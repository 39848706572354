import React, { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="about">
                    <div className="row">
                        <div className="three columns">
                            <img className="profile-pic" src="images/rod-oliveira.jpg" alt="profile" />
                        </div>
                        <div className="nine columns main-col">
                            <h2>About Me</h2>
                            <p>
                                My name is João Rodolfo Suárez de Oliveira, a.k.a. Rod Oliveira.
                                I am based in Halifax, Canada, and enjoy working with back end
                                development using Java, Spring Boot, Micro-Services, AWS Cloud, and
                                open source solutions for system integration (e.g. Apache Kafka and Apache Camel).
                                I am also an AI enthusiast and I have certifications in AI and have experience
                                implementing Retrieval Augmented Generation in Python.                            </p>
                            <div className="row">
                                <div className="columns contact-details">
                                    <h2>Contact Details</h2>
                                    <p className="address">
                                        <span>Rod Oliveira</span>
                                        <br />
                                        <span>
                                            Halifax, Canada<br />
                                        </span>
                                        <span>jrodolfo@gmail.com</span>
                                    </p>
                                </div>
                                <div className="columns download">
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer" href="/pdf/rod-oliveira-resume.pdf" className="button">
                                            <i className="fa fa-download" />Download Resume
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
